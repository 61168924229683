import { MainButton } from "@zakarliuka/react-telegram-web-tools";
import { isPresent } from "../helpers";
import { Attachments, AttachmentsList, UploadFile } from "./attachments";
import { FormNavigation, useForm } from "./form";
import { InputWrapper } from "./input-wrapper";
import { useAppSettings } from "./ticket-provider";

export const DogsDescriptionStep = () => {
  const { photosRequired } = useAppSettings();
  const { state, setState, submit } = useForm();

  const streetValue = state["description"];
  const attachments = state["attachments"];

  const hasUploadedAttachments =
    Array.isArray(attachments) &&
    attachments.filter((att) => isPresent(att.uploaded)).length > 0;

  const hasCaughtDogs = !!state["dogsCaught"];

  // const showSendBtn = photosRequired
  //   ? hasUploadedAttachments || !hasCaughtDogs
  //   : true;

  const showSendBtn = true;

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex-1">
        <InputWrapper title="Фотографии">
          <Attachments
            attachments={attachments}
            setAttachments={(attachments) => {
              setState((state) => ({ ...state, ["attachments"]: attachments }));
            }}
          >
            <div className="space-y-3">
              <AttachmentsList />
              <UploadFile />
            </div>
          </Attachments>
        </InputWrapper>

        <InputWrapper title="Описание">
          <textarea
            placeholder="Укажите описание"
            className="field resize-none"
            rows={3}
            value={streetValue}
            onInput={(evt) => {
              setState((state) => ({
                ...state,
                ["description"]: evt.currentTarget.value,
              }));
            }}
          />
        </InputWrapper>
      </div>
      <FormNavigation disabled />

      {showSendBtn && <MainButton onClick={submit} text="Отправить!" />}
    </div>
  );
};
